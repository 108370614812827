import React from 'react';
import { createGlobalState } from 'react-hooks-global-state';
import type { DefaultTheme } from 'styled-components';
import type { PreviewBlogPost } from '../union-types/blogPost';
import type { PreviewHomepage } from '../union-types/homepage';
import type { PreviewInUsePost } from '../union-types/inUsePost';
import { ErrorOverlayType } from './ErrorOverlay';
import isBrowser from '../utils/isBrowser';

export interface GlobalRuntimeStateType {
    isMenuOpen: boolean;
    isCartShown: boolean;
    navColors:
        | {
              background: string;
              foreground: string;
              hoverBackground?: string;
              hoverForeground?: string;
          }
        | undefined;
    isFooterInverted: boolean;
    sectionInView?: string;
    isAdminColorPickerShown: boolean;
    adminColorScheme?: DefaultTheme;
    siteOverlay: {
        isVisible?: boolean;
        isForcedVisible?: boolean;
        isUberAlles?: boolean;
    };
    errorOverlay: {
        isShown: boolean;
        type?: ErrorOverlayType;
        hideButton?: boolean;
    };
    viewportWidth?: number;
    viewportHeight?: number;
    viewportWidthWithoutMargins?: number;
    modal: {
        isVisible?: boolean;
        message?: React.ReactNode;
        closeButtonText?: string;
        primaryButtonCallback?: () => void;
        primaryButtonText?: React.ReactNode;
        isUberAlles?: boolean;
    };
    previewHomepage?: PreviewHomepage;
    previewInUsePost?: PreviewInUsePost;
    previewBlogPost?: PreviewBlogPost;
    previewBlogPostWordCount?: number;
    pageHasMounted: boolean;
    openSelectRef?: React.MutableRefObject<boolean>;
}

export const { useGlobalState } = createGlobalState<GlobalRuntimeStateType>({
    isMenuOpen: false,
    isCartShown: false,
    navColors: undefined,
    isFooterInverted: false,
    sectionInView: undefined,
    isAdminColorPickerShown: false,
    adminColorScheme: undefined,
    viewportWidth: isBrowser() ? window.innerWidth : undefined,
    viewportHeight: isBrowser() ? window.innerHeight : undefined,
    viewportWidthWithoutMargins: undefined,
    siteOverlay: {
        isVisible: false,
        isForcedVisible: false,
    },
    errorOverlay: {
        isShown: false,
        type: undefined,
    },
    modal: {
        isVisible: undefined,
        message: undefined,
        closeButtonText: undefined,
        primaryButtonCallback: undefined,
        primaryButtonText: undefined,
    },
    previewHomepage: undefined,
    previewInUsePost: undefined,
    previewBlogPost: undefined,
    previewBlogPostWordCount: undefined,
    pageHasMounted: false,
    openSelectRef: undefined,
});

export function useErrorOverlayState(): ReturnType<
    typeof useGlobalState<'errorOverlay'>
> {
    const errorOverlayState = useGlobalState('errorOverlay');
    /*
     * Allows anyone to test the error overlay by entering the following into the dev console:
     *  window.showFatalError();
     */
    React.useEffect((): void => {
        window.showFatalError = (): void =>
            errorOverlayState[1]({
                isShown: true,
            });
    }, []);
    return errorOverlayState;
}

export function usePreviewHomepage(): PreviewHomepage | undefined {
    const [previewPage] = useGlobalState('previewHomepage');
    return previewPage;
}

export function usePreviewBlogPost(): PreviewBlogPost | undefined {
    const [previewPage] = useGlobalState('previewBlogPost');
    return previewPage;
}

export function usePreviewBlogPostWordCount(): number | undefined {
    const [value] = useGlobalState('previewBlogPostWordCount');
    return value;
}

export function usePreviewInUsePost(): PreviewInUsePost | undefined {
    const [previewPage] = useGlobalState('previewInUsePost');
    return previewPage;
}
