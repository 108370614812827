import { useStaticQuery, graphql } from 'gatsby';
import type { AllFontsQuery } from '../gql/api-ssr';
import useConfig from './useConfig';
import isWhitelisted from '../utils/isWhitelisted';
import useIsUserTestingSite from './useIsUserTestingSite';

export type FontFamilyGroup = AllFontsQuery['ssr']['fontFamilyGroups'][number];
export type FontFamily = FontFamilyGroup['fontFamilies'][number];
export type MainFontStyle = FontFamily['mainFontStyle'];
export type FontStyle = FontFamily['fontStyles'][number];

export default function useAllFonts(): FontFamilyGroup[] {
    const config = useConfig();
    const isUserTestingSite = useIsUserTestingSite();
    const query = useStaticQuery<AllFontsQuery>(graphql`
        query AllFontsQuery {
            ssr {
                fontFamilyGroups {
                    __typename
                    id
                    name
                    slug
                    isCollection
                    isNew
                    informationIntro
                    showInCustomFontMenu
                    svgNavigation
                    typographicRanking
                    designInformationPage {
                        slug
                    }
                    colorSchemes {
                        backgroundColor: colorBackground
                        foregroundColor: colorForeground
                        highlightColor: colorHighlight
                        backgroundColorAlt: colorBackgroundAlt
                        foregroundColorAlt: colorForegroundAlt
                        highlightColorAlt: colorHighlightAlt
                    }
                    fontFamilies {
                        id
                        name
                        slug
                        isPreRelease
                        fontStyleCount
                        release
                        fontSizeMultiplier
                        typographicRanking
                        fontStyles {
                            ...FontStyleBasicFragment
                        }
                        mainFontStyle {
                            ...FontStyleBasicFragment
                            metricTtfUnitsPerEmHead
                            metricTtfCapHeightOs2
                            metricTtfAscentCalc
                            metricTtfAscentHhea
                            metricTtfDescentCalc
                            metricTtfDescentHhea
                            metricTtfLineGapHhea
                        }
                    }
                }
            }
        }
        fragment FontStyleBasicFragment on CMS_SSR_FontStyleInterface {
            id
            name
            weight
            typographicRanking
            woff2
        }
    `);

    return (
        query.ssr.fontFamilyGroups.filter((fontFamilyGroup) => {
            if (!isUserTestingSite) {
                return true;
            }
            return isWhitelisted(
                config.userTestingFontFamilyGroupWhitelist,
                fontFamilyGroup.id,
            );
        }) || []
    );
}
