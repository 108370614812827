import React from 'react';
import styled, { css } from 'styled-components';
import { Link, A } from './Primitives';
import InlineUnderlinedLink from './InlineUnderlinedLink';
import FragmentLink from './FragmentLink';
import SubscribeForm from './SubscribeForm';
import _Icon, { IconType, SocialIcon } from './Icon';
import { VIEWPORT } from '../settings/Global';
import { Accordion, AccordionItem } from './Accordion';
import { ReactComponent as KlimLogo } from '../assets/images/klim-logotype-2020-horizontal.svg';
import {
    createAboutUrl,
    createAccountUrl,
    createAwardsAndAccoladesUrl,
    createBlogIndexUrl,
    createCollectionUrl,
    createContactUrl,
    createCustomFontUrl,
    createFaqUrl,
    createFontFamilyUrl,
    createEulaOverviewUrl,
    createFontsInUseUrl,
    createHomeUrl,
    createInterviewUrl,
    createPrivacyPolicyUrl,
    createRefundPaymentPolicyUrl,
    createSubscribeUrl,
    createTestFontsUrl,
    createTopFragmentUri,
} from '../utils/urlHelper';
import { hideFromPrint, invertColorVariables } from '../utils/stylesMixins';
import useAllFonts from '../hooks/useAllFonts';
import type { FontFamilyGroup } from '../hooks/useAllFonts';
import notPreRelease from '../utils/notPreRelease';
import useCustomFontsStubs from '../hooks/useCustomFontsStubs';
import type { CustomFontsStub } from '../hooks/useCustomFontsStubs';
import useConfig from '../hooks/useConfig';
import type { Config } from '../hooks/useConfig';
import sortFontsByTypographicRanking from '../utils/sortFontsByTypographicRanking';
import sortByName from '../utils/sortByName';
import formatDjangoDateString from '../utils/formatDjangoDateString';
import { useGlobalState } from './GlobalRuntimeState';
import { SOCIAL_LINK_DEFS } from '../settings/Social';

const Container = styled.footer<{ $isInverted: boolean }>`
    ${({ $isInverted }): ReturnType<typeof css> | null => {
        return $isInverted ? invertColorVariables : null;
    }};

    position: relative;
    padding: max(50vh, 100px) var(--gridMarginGap) var(--gridMarginGap);
    display: grid;
    align-items: baseline;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);

    --color: var(--foregroundColorMix4);
    --hoverColor: var(--foregroundColor);

    background-color: var(--backgroundColor);

    ${Link}, a {
        color: var(--color);

        &:hover,
        &:focus,
        &:active {
            color: var(--hoverColor);
        }
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-row-gap: var(--spacing1);
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-row-gap: 0;
    }
`;

const MinimalColumn = styled.div`
    &:nth-child(1) {
        grid-column: 1 / span 2;

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            grid-column: 1 / span 4;
        }
    }

    &:nth-child(2) {
        grid-column: 3 / span 4;

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            grid-column: 6 / -1;
        }

        @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
            grid-column: 5 / -1;
        }
    }

    &:nth-child(3) {
        grid-column: 10 / -1;
    }
`;

const Column = styled.div`
    color: var(--foregroundColor);

    &:nth-child(1) {
        /* Little bug fix for vertical alignment in Firefox... */
        align-self: self-end;
        grid-column: 1 / span 5;
    }

    &:nth-child(4) {
        grid-column: 1 / span 6;
    }

    &:nth-child(2),
    &:nth-child(5) {
        grid-column: 7 / span 2;
    }

    &:nth-child(2) {
        line-height: var(--lineHeightBody1);
    }

    &:nth-child(3) {
        grid-column: 9 / -1;
    }

    &:nth-child(6) {
        grid-column: 9 / span 2;
    }

    &:nth-child(7),
    &:nth-child(11) {
        grid-column: 11 / -1;
    }

    &:nth-child(8) {
        grid-column: 1 / span 2;
        line-height: var(--lineHeightBody1);
    }

    &:nth-child(9) {
        grid-column: 3 / span 4;
        line-height: var(--lineHeightBody1);
    }

    &:nth-child(10) {
        grid-column: 7 / span 4;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        &:nth-child(1) {
            grid-column: 1 / -1;
        }

        &:nth-child(2) {
            grid-column: 1 / span 2;
        }

        &:nth-child(3) {
            grid-column: 3 / -1;
        }

        &:nth-child(4) {
            grid-column: 1 / span 4;
        }

        &:nth-child(5) {
            grid-column: 5 / span 2;
        }

        &:nth-child(6) {
            grid-column: 1 / span 3;
        }

        &:nth-child(7) {
            grid-column: 4 / -1;
        }

        &:nth-child(8),
        &:nth-child(10) {
            grid-column: 1 / span 3;
        }

        &:nth-child(9) {
            grid-column: 4 / span 2;
        }

        &:nth-child(11) {
            grid-column: 4 / -1;
        }
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(7) {
            grid-column: 1 / -1;
        }

        &:nth-child(4),
        &:nth-child(5) {
            margin-bottom: var(--spacing5);
        }
    }
`;

const Logo = styled.svg`
    display: inline-grid;
    width: 100%;
    overflow: visible;

    /* Using both color and fill as some of the SVG's respond to 'fill' and some to 'color' */
    fill: var(--foregroundColor);
    color: var(--foregroundColor);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin-bottom: var(--spacing7);
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        margin-bottom: var(--spacing6);
    }
`;

const ColumnHeading = styled.div`
    margin-top: var(--spacing8);
    padding-bottom: var(--spacing1);
    margin-bottom: var(--spacing1);
    border-bottom: 1px solid var(--borderColor);
    color: var(--foregroundColor);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin-top: 0;
    }
`;

const FooterText = styled.span<{ $preFormat?: boolean }>`
    font-feature-settings: 'tnum';
    color: var(--foregroundColorMix4);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        ${({ $preFormat }): ReturnType<typeof css> | null => {
            return $preFormat
                ? css`
                      white-space: pre-wrap;
                  `
                : null;
        }};
    }
`;

const FooterList = styled.ul`
    margin-bottom: var(--spacing7);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        columns: 2;
        margin-bottom: 0;
    }
`;

const FontList = styled(FooterList)`
    display: block;
    column-count: 3;
    column-gap: var(--gridColumnGap);
    margin-bottom: 0;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        columns: 2;
        margin-bottom: 0;
    }
`;

const CustomFontList = styled(FooterList)`
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin-bottom: var(--spacing2);
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        margin-bottom: 0;
    }
`;

const IconLinks = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        padding: var(--spacing6) var(--spacing2);
        font-size: var(--fontSizeFixed5);
    }
`;

const IconLink = styled(A)`
    padding: 0.5em;
    margin: -0.5em;
    -webkit-tap-highlight-color: transparent;

    --color: var(--foregroundColor);
    --hoverColor: var(--foregroundColorMix3);
`;

const JumpToTopLink = styled(FragmentLink)`
    ${hideFromPrint};

    -webkit-tap-highlight-color: transparent;

    --color: var(--foregroundColor);
    --hoverColor: var(--foregroundColorMix3);
`;

const GetFontNewsLink = styled(InlineUnderlinedLink)`
    --color: var(--foregroundColor);
    --hoverColor: var(--foregroundColorMix3);
`;

const Icon = styled(_Icon)`
    display: inline-block;
`;

const TestFontLink = styled(Link)`
    display: block;
    margin-bottom: var(--spacing7);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin-bottom: var(--spacing2);
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        margin-bottom: 0;
    }
`;

function getFontLink(fontFamilyGroup: FontFamilyGroup): React.ReactElement {
    if (fontFamilyGroup.isCollection) {
        return (
            <Link to={createCollectionUrl(fontFamilyGroup.slug)}>
                {fontFamilyGroup.name}
            </Link>
        );
    }
    const fontFamily = fontFamilyGroup.fontFamilies
        .filter(notPreRelease)
        .sort(sortFontsByTypographicRanking)[0];
    return (
        <Link to={createFontFamilyUrl(fontFamily.slug)}>
            {fontFamilyGroup.name}
        </Link>
    );
}

function useSocialIcons(): React.ReactElement {
    return (
        <IconLinks>
            {SOCIAL_LINK_DEFS.map((socialLinkDef) => (
                <IconLink
                    key={socialLinkDef.title}
                    title={socialLinkDef.title}
                    href={socialLinkDef.url}
                >
                    <SocialIcon type={socialLinkDef.iconType} />
                </IconLink>
            ))}
        </IconLinks>
    );
}

function useCopyright(preFormat?: boolean): React.ReactElement {
    return (
        <FooterText
            $preFormat={preFormat}
        >{`Copyright © 2005 – ${new Date().getFullYear()}. \nAll rights reserved.`}</FooterText>
    );
}

function useFontList(fontFamilyGroups: FontFamilyGroup[]): React.ReactElement {
    return React.useMemo(
        () => (
            <>
                <FontList>
                    {fontFamilyGroups.map(
                        (
                            fontFamilyGroup: FontFamilyGroup,
                        ): React.ReactElement => (
                            <li key={fontFamilyGroup.id}>
                                {getFontLink(fontFamilyGroup)}
                            </li>
                        ),
                    )}
                </FontList>
                <br />
                <TestFontLink to={createTestFontsUrl()}>
                    Download test fonts <Icon type={IconType.DOWNLOAD} />
                </TestFontLink>
            </>
        ),
        [fontFamilyGroups],
    );
}

function useCustomFontList(
    customFontsStubs: CustomFontsStub[],
): React.ReactElement {
    return React.useMemo(
        () => (
            <CustomFontList>
                {customFontsStubs.map(
                    (customFont: CustomFontsStub): React.ReactElement => (
                        <li key={customFont.id}>
                            <Link
                                to={createCustomFontUrl(customFont.slug || '')}
                            >
                                {customFont.name}
                            </Link>
                        </li>
                    ),
                )}
            </CustomFontList>
        ),
        [customFontsStubs],
    );
}

function useInformationList(): React.ReactElement {
    return (
        <FooterList>
            <li>
                <Link to={createAboutUrl()}>About</Link>
            </li>
            <li>
                <Link to={createBlogIndexUrl()}>Blog</Link>
            </li>
            <li>
                <Link to={createInterviewUrl()}>Interviews</Link>
            </li>
            <li>
                <Link to={createAwardsAndAccoladesUrl()}>Awards</Link>
            </li>
            <li>
                <Link to={createFontsInUseUrl()}>Fonts in use</Link>
            </li>
            <li>
                <Link to={createAccountUrl()}>Account</Link>
            </li>
        </FooterList>
    );
}

function useSupportList(): React.ReactElement {
    return (
        <FooterList>
            <li>
                <Link to={createContactUrl()}>Contact</Link>
            </li>
            <li>
                <Link to={createFaqUrl()}>FAQs</Link>
            </li>
            <li>
                <Link to={createEulaOverviewUrl()}>Font licences</Link>
            </li>
            <li>
                <Link to={createRefundPaymentPolicyUrl()}>
                    Payments & refunds
                </Link>
            </li>
            <li>
                <Link to={createPrivacyPolicyUrl()}>Privacy policy</Link>
            </li>
        </FooterList>
    );
}

function useJumpToTopLink(): React.ReactElement {
    return (
        <JumpToTopLink href={createTopFragmentUri()} updateUri={false}>
            Back to top <Icon type={IconType.ARROW_UP} />
        </JumpToTopLink>
    );
}

function useBuildDate(config: Config): React.ReactElement {
    return (
        <FooterText>
            <Icon type={IconType.BUILD_TIME} /> Site build{' '}
            {formatDjangoDateString(config.buildDateTime, true)}
        </FooterText>
    );
}

export default function Footer(): React.ReactElement {
    const [viewportWidth] = useGlobalState('viewportWidth');
    const [isFooterInverted] = useGlobalState('isFooterInverted');
    const allFonts = useAllFonts();
    const customFontsStubs = useCustomFontsStubs();

    const fontFamilyGroups = React.useMemo(
        () =>
            allFonts
                .filter(
                    // Filter out groups without any families in them
                    (fontFamilyGroup): boolean =>
                        Boolean(
                            fontFamilyGroup.fontFamilies.filter(notPreRelease)
                                .length > 0,
                        ),
                )
                .sort(sortFontsByTypographicRanking),
        [allFonts],
    );
    const fontList = useFontList(fontFamilyGroups);

    const filteredCustomFontsStubs = React.useMemo(
        () => customFontsStubs.filter(notPreRelease).sort(sortByName),
        [customFontsStubs],
    );
    const customFontList = useCustomFontList(filteredCustomFontsStubs);

    const informationList = useInformationList();
    const supportList = useSupportList();
    const jumpToTopLink = useJumpToTopLink();
    const copyright = useCopyright();
    const socialIcons = useSocialIcons();

    const config = useConfig();
    const buildDate = useBuildDate(config);

    return (
        <Container $isInverted={isFooterInverted}>
            <Column>
                <Link to={createHomeUrl()}>
                    <Logo as={KlimLogo} />
                </Link>
            </Column>
            {!viewportWidth || viewportWidth > VIEWPORT.MOBILE ? (
                <>
                    <Column>Get news about our fonts</Column>
                    <Column>
                        <SubscribeForm isFooter={true} />
                    </Column>
                    <Column>
                        <ColumnHeading>Fonts</ColumnHeading>
                        {fontList}
                    </Column>
                    <Column>
                        <ColumnHeading>Custom fonts</ColumnHeading>
                        {customFontList}
                    </Column>
                    <Column>
                        <ColumnHeading>Information</ColumnHeading>
                        {informationList}
                    </Column>
                    <Column>
                        <ColumnHeading>Support</ColumnHeading>
                        {supportList}
                    </Column>
                    <Column>{jumpToTopLink}</Column>
                    {viewportWidth && viewportWidth <= VIEWPORT.TABLET ? (
                        <Column>{socialIcons}</Column>
                    ) : (
                        <Column>{copyright}</Column>
                    )}
                    <Column>{buildDate}</Column>
                    {viewportWidth && viewportWidth <= VIEWPORT.TABLET ? (
                        <Column>{copyright}</Column>
                    ) : (
                        <Column>{socialIcons}</Column>
                    )}
                </>
            ) : (
                <>
                    <Column>
                        <Accordion>
                            <AccordionItem
                                heading="Fonts"
                                headingLevel={3}
                                panel={fontList}
                                compact={true}
                            />
                            <AccordionItem
                                heading="Custom fonts"
                                headingLevel={3}
                                panel={customFontList}
                                compact={true}
                            />
                            <AccordionItem
                                heading="Information"
                                headingLevel={3}
                                panel={informationList}
                                compact={true}
                            />
                            <AccordionItem
                                heading="Support"
                                headingLevel={3}
                                panel={supportList}
                                compact={true}
                            />
                        </Accordion>
                    </Column>
                    <Column>{socialIcons}</Column>
                    <Column>
                        <GetFontNewsLink to={createSubscribeUrl()}>
                            Get news about our fonts
                        </GetFontNewsLink>
                    </Column>
                    <Column>{jumpToTopLink}</Column>
                    <Column>{buildDate}</Column>
                    <Column>{copyright}</Column>
                </>
            )}
        </Container>
    );
}

export function FooterMinimal({
    isBuyOrCheckoutPage,
}: {
    isBuyOrCheckoutPage?: boolean;
}): React.ReactElement {
    const [viewportWidth] = useGlobalState('viewportWidth');
    const [isFooterInverted] = useGlobalState('isFooterInverted');

    const jumpToTopLink = useJumpToTopLink();
    const copyright = useCopyright(true);

    const config = useConfig();
    const buildDate = useBuildDate(config);

    return (
        <Container $isInverted={isFooterInverted}>
            {!viewportWidth || viewportWidth > VIEWPORT.TABLET ? (
                <>
                    <MinimalColumn>{jumpToTopLink}</MinimalColumn>
                    <MinimalColumn>{copyright}</MinimalColumn>
                    {!isBuyOrCheckoutPage && (
                        <MinimalColumn>{buildDate}</MinimalColumn>
                    )}
                </>
            ) : (
                <>
                    <MinimalColumn>{copyright}</MinimalColumn>
                    <MinimalColumn>{jumpToTopLink}</MinimalColumn>
                </>
            )}
        </Container>
    );
}
