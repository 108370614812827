import React from 'react';
import styled, { css } from 'styled-components';
import type { Property } from 'csstype';
import {
    useColumnsState,
    useFontSizeState,
    useFontStyleState,
    useLetterSpacingState,
    useLineHeightState,
    usePreventWrappingState,
    useSpecimenContext,
    useSpecimensRenderWidthState,
    useTextAlignState,
} from './TypeEditorContext';
import getCssFontFamilyNameFromId from '../utils/getCssFontFamilyNameFromId';
import getSafeCssStringValue from '../utils/getSafeCssStringValue';
import { applyMinimumFontSize } from '../utils/GeneratedSpecimen';
import { TEST_ID } from '../settings/E2e';
import { hyphensAuto } from '../utils/stylesMixins';
import { useGlobalState } from './GlobalRuntimeState';
import {
    FontMetricOffsets,
    FontMetricsCalculator,
} from '../utils/fontMetricsCalculator';
import { sentryMessage } from '../utils/sentry';

// Props prefixed with $ are seen as transient props in Styled Components.
const Container = styled.div<{
    $preventWrapping: boolean;
}>`
    position: relative;
    ${hyphensAuto};
    column-gap: var(--spacing2);

    /*
     * Bit of a hack to get around the fact that DraftJS' default styling forces text wrapping,
     * and this prevents us having to use a custom blockRenderMap.
     *
     * E.g. see https://github.com/facebook/draft-js/issues/1243
     */
    ${({ $preventWrapping }): ReturnType<typeof css> | undefined =>
        $preventWrapping
            ? css`
                  /* stylelint-disable-next-line selector-class-pattern */
                  .public-DraftEditor-content {
                      white-space: nowrap !important;
                      word-break: normal !important;
                      hyphens: none !important;
                  }
              `
            : undefined};
`;

function TypeEditorStyles({
    children,
}: React.PropsWithChildren): React.ReactElement {
    const specimen = useSpecimenContext();
    const [fontStyle] = useFontStyleState();
    const [textAlign] = useTextAlignState();
    const [columns] = useColumnsState();
    const [preventWrapping] = usePreventWrappingState();
    const [letterSpacing] = useLetterSpacingState();
    const [fontSize, setFontSize] = useFontSizeState();
    const [lineHeight] = useLineHeightState();
    const [renderWidth, setRenderWidth] = useSpecimensRenderWidthState();
    const [viewportWidth] = useGlobalState('viewportWidth');

    // Update the font size when the viewport changes
    React.useEffect(() => {
        if (!viewportWidth || renderWidth === viewportWidth) {
            return;
        }
        const newFontSize = applyMinimumFontSize(
            viewportWidth,
            specimen.type,
            Math.round(fontSize * (viewportWidth / renderWidth)),
        );
        setRenderWidth(viewportWidth);
        if (newFontSize !== fontSize) {
            setFontSize(newFontSize);
        }
    }, [viewportWidth]);

    const fontMetricSizeOptions: FontMetricOffsets | undefined =
        React.useMemo(() => {
            if (specimen.metricTtfUnitsPerEmHead === null) {
                sentryMessage(
                    `Specimen for ${specimen.fontStyleName} has no metricTtfUnitsPerEmHead!`,
                );
                return;
            }
            if (specimen.metricTtfCapHeightOs2 === null) {
                sentryMessage(
                    `Specimen for ${specimen.fontStyleName} has no metricTtfCapHeightOs2!`,
                );
                return;
            }
            if (specimen.metricTtfAscentCalc === null) {
                sentryMessage(
                    `Specimen for ${specimen.fontStyleName} has no metricTtfAscentCalc!`,
                );
                return;
            }
            if (specimen.metricTtfAscentHhea === null) {
                sentryMessage(
                    `Specimen for ${specimen.fontStyleName} has no metricTtfAscentHhea!`,
                );
                return;
            }
            if (specimen.metricTtfDescentCalc === null) {
                sentryMessage(
                    `Specimen for ${specimen.fontStyleName} has no metricTtfDescentCalc!`,
                );
                return;
            }
            if (specimen.metricTtfDescentHhea === null) {
                sentryMessage(
                    `Specimen for ${specimen.fontStyleName} has no metricTtfDescentHhea!`,
                );
                return;
            }
            if (specimen.metricTtfLineGapHhea === null) {
                sentryMessage(
                    `Specimen for ${specimen.fontStyleName} has no metricTtfLineGapHhea!`,
                );
                return;
            }
            return new FontMetricsCalculator({
                lineHeight,
                unitsPerEm: specimen.metricTtfUnitsPerEmHead,
                capHeight: specimen.metricTtfCapHeightOs2,
                ascentCalc: specimen.metricTtfAscentCalc,
                ascent: specimen.metricTtfAscentHhea,
                descentCalc: specimen.metricTtfDescentCalc,
                descent: specimen.metricTtfDescentHhea,
                lineGap: specimen.metricTtfLineGapHhea,
            }).calculate();
        }, [specimen, lineHeight]);

    const containerStyles: React.CSSProperties = React.useMemo(() => {
        let maxHeightString = specimen.lineCount
            ? // Set a specific height, in multiples of `lh` (line height) units.
              `${specimen.lineCount}lh`
            : undefined;
        // When the line height is small, and it starts clipping the descender, we need
        // to account for it when setting the max-height for `specimen.lineCount`.
        if (maxHeightString && fontMetricSizeOptions) {
            maxHeightString = `calc(${maxHeightString} + ${fontMetricSizeOptions.descent}em)`;
        }
        return {
            fontFamily: getSafeCssStringValue(
                getCssFontFamilyNameFromId(fontStyle),
            ),
            textAlign: textAlign as Property.TextAlign,
            letterSpacing: `${letterSpacing}em`,
            columns,
            fontSize: `${fontSize}px`,
            lineHeight,
            maxHeight: maxHeightString,
            overflow: specimen.lineCount ? 'hidden' : undefined,
            /*
            Since we're not worried about margin collapse, we can apply
            the margins here, directly, without resorting to pseudo :before
            and :after elements, which are buggy in Safari and when using multiple
            columns.
            See https://seek-oss.github.io/capsize/#faq
            */
            marginBottom: fontMetricSizeOptions
                ? `${fontMetricSizeOptions.descent}em`
                : undefined,
            marginTop: fontMetricSizeOptions
                ? `${-fontMetricSizeOptions.ascent}em`
                : undefined,
        };
    }, [
        columns,
        fontSize,
        fontStyle,
        letterSpacing,
        lineHeight,
        specimen.lineCount,
        textAlign,
        viewportWidth,
        fontMetricSizeOptions,
    ]);

    return (
        <Container
            style={containerStyles}
            $preventWrapping={preventWrapping}
            data-cy={TEST_ID.TYPE_EDITOR_STYLE}
        >
            {children}
        </Container>
    );
}

export default React.memo(TypeEditorStyles);
